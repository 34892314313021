define("discourse/plugins/discourse-gamification/discourse/admin-discourse-gamification-plugin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins.show",
    path: "/plugins",
    map() {
      this.route("discourse-gamification-leaderboards", {
        path: "leaderboards"
      });
    }
  };
});